@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:ital,wght@0,300;0,400;1,300;1,400);
body {
  margin: 8px;
  font-family: 'Ubuntu Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #383E4A;
  color: #dddddd;
  font-size: 18px
}

code {
  font-family: 'Source Code Pro', monospace;
  color: #FF5959;
}

h1 {
  color: #58CBCC
}

h2 {
  color: #58CBCC
}

h3 {
  color: #58CBCC
}

h4 {
  color: #58CBCC
}

a {
  color: #58CBCC
}

hr {
  border-top: 1px solid #FF5959;
  border-bottom: 0px solid #FF5959;
}

button {
  font-family: 'Ubuntu Mono', monospace;
  background-color: #383E4A;
  border-color: #58CBCC;
  color: #58CBCC;
  font-size: 18px;
}

button:hover {
  color: #ffffff;
}

input {
  font-family: 'Ubuntu Mono', monospace;
  background-color: #383E4A;
  border-color: #58CBCC;
  color: #58CBCC;
  font-size: 18px;
}

input:hover {
  color: #ffffff;
}

.FlowViz {
  background-color: #282D36;
  max-width: 100%
}

.DagViz {
  background-color: #282D36;
  max-width: 100%
}

.FlowRunViz {
  background-color: #282D36;
  max-width: 100%
}

.DagRunViz {
  background-color: #282D36;
  max-width: 100%
}

